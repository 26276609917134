import request from "@/api/requestNew";

/**
 * 服务类型列表(供选择)
 * @param data
 * @returns {*}
 */
export const serviceTypeAll = (data) => request({url: 'service_type/serviceTypeAll', method: 'post', data});
export const serviceTypeByUser = (data) => request({url: 'service_type/serviceTypeByUser', method: 'post', data});
export const updateTypeFee = (data) => request({url: 'service_type/updateTypeFee', method: 'post', data});
export const biaozhunAll = (data) => request({url: 'biaozhun/biaozhunAll', method: 'post', data});
export const setUserBiaozhun = (data) => request({url: 'biaozhun/setUserBiaozhun', method: 'post', data});
export const serviceRangeAll = (data) => request({url: 'service_range/serviceRangeAll', method: 'post', data});
export const addFee = (data) => request({url: 'fee_setting_record/addFee', method: 'post', data});