<template>
	<div>
		<el-card>
      <el-row class="left">
        <el-col :span="24">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="门店名称:">
              <el-input v-model="queryForm.store_name" placeholder="请输入门店名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="服务类型">
              <el-select v-model="queryForm.type_id" placeholder="请选择服务类型" clearable width="100%" style="display: block;">
                <el-option v-for="item in typeList" :key="item.type_id" :label="item.type_name" :value="item.type_id"/>
              </el-select>
            </el-form-item>
            <el-form-item label="入驻状态">
              <el-select v-model="queryForm.entry_status" placeholder="请选择入驻状态" clearable width="100%" style="display: block;">
                <el-option v-for="item in entryStatusList" :key="item.value" :label="item.name" :value="item.value"/>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
              <el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
              <el-button type="primary" icon="el-icon-plus" @click="addStore">新增门店</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
<!--			<el-row :gutter="20">-->
<!--				<el-col :span="6">-->
<!--					<el-input placeholder="请输入门店名称" v-model="queryInfo.query" clearable @clear="getStore">-->
<!--						<el-button slot="append" icon="el-icon-search" @click="getStore"></el-button>-->
<!--					</el-input>-->
<!--				</el-col>-->
<!--				<el-col :span="3">-->
<!--					<el-button type="primary" icon="el-icon-plus" @click="addStore">新增门店</el-button>-->
<!--				</el-col>-->
<!--			</el-row>-->

			<!-- 服务列表 -->
			<el-table :data="storeList" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column prop="store_id" label="ID" width="100px"></el-table-column>
				<el-table-column label="封面图">
					<template slot-scope="scope">
						<div>
							<el-avatar shape="square" size="large" :src="scope.row.store_logo"></el-avatar>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="store_name" label="门店名称" :show-overflow-tooltip="true" width="180px"></el-table-column>
				<el-table-column prop="store_phone" label="门店电话" width="120px"></el-table-column>
				<el-table-column label="上班时间" width="120px">
					<template slot-scope="scope">
						<div>{{scope.row.store_start}}-{{scope.row.store_end}}</div>
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" prop="address" label="所处地址" width="200px"></el-table-column>
				<el-table-column label="是否停用">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.is_close" @change="changeState(scope.row)">
						</el-switch>
					</template>
				</el-table-column>
        <el-table-column label="停牌状态" :show-overflow-tooltip="true" min-width="150px">
          <template slot-scope="scope">
            <div v-for="(v,k) in scope.row.type_list" :key="k" style="margin-top: 10px;">
              <div v-if="v.stop_type && v.stop_type > 0">
                <el-tag type="danger" v-if="v.is_stop && v.stop_type === 1">{{v.type_name}}停牌24小时</el-tag>
                <el-tag type="danger" v-if="v.is_stop && v.stop_type === 2">{{v.type_name}}停牌48小时</el-tag>
                <el-tag type="danger" v-if="v.is_stop && v.stop_type === 3">{{v.type_name}}永久停牌</el-tag>
                <el-tag type="danger" v-if="v.is_stop && v.stop_type === 4">{{v.type_name}}停牌{{v.stop_start}}至{{v.stop_end}}</el-tag>
                <el-tag type="success" v-if="!v.is_stop && v.stop_type === 4">{{v.type_name}}停牌{{v.stop_start}}至{{v.stop_end}}</el-tag>
              </div>
              <div v-else>
                <el-tag type="danger" v-if="v.is_stop">{{v.type_name}}已停牌</el-tag>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="服务类型" width="220px">
          <template slot-scope="scope">
            <el-tag type="primary" disable-transitions v-for="(item,index) in scope.row.type_list" :key="index" style="margin-left: 5px;">{{item.type_name}}-{{item.career}}&nbsp;&nbsp;</el-tag>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="入驻状态">
          <template slot-scope="scope">
            <el-tag type="success" disable-transitions v-if="scope.row.is_entry">已入驻</el-tag>
            <el-tag type="danger" disable-transitions v-else>未入驻</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="entry_time" label="入驻时间" :show-overflow-tooltip="true" width="150"></el-table-column>
				<el-table-column prop="add_time" label="创建时间" width="150px"></el-table-column>
				<el-table-column fixed="right" label="操作" width="280px">
					<template slot-scope="scope">
            <el-row style="margin-bottom:0px">
						<el-button size="mini" type="primary" plain  @click="editStore(scope.row.store_id)">编辑</el-button>
<!--						<el-button size="mini" type="warning" plain @click="changeBz(scope.row)">分佣标准</el-button>-->
<!--            </el-row>-->
<!--            <el-row style="margin-bottom:10px">-->
            <el-button size="mini" type="success" plain @click="openStop(scope.row)">停牌设置</el-button>
            <el-button size="mini" type="primary" plain @click="relationService(scope.row)">关联服务</el-button>
            </el-row>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>

      <el-dialog title="修改分佣标准" width="600px" :visible.sync="bzVisible">
        <el-form label-width="80px">
          <el-tabs v-model="activeName" @tab-click="handleBiaozhunClick" style="margin-bottom: 0px;">
            <el-tab-pane v-for="(item,index) in biaozhunTypeList" :label="item.type_name" :name="'type@'+item.type_id" style="margin-top: 15px;">
              <el-form-item label="分佣标准:">
                <el-select v-model="item.biaozhun_id" placeholder="请选择" style="width: 100%;" clearable @change="changeBiaozhun">
                  <el-option
                      v-for="item in biaozhun_list"
                      :key="item.biaozhun_id"
                      :label="item.biaozhun_name"
                      :value="item.biaozhun_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>
          <!-- 发展技师 -->
          <el-row class="fy-row">
            <el-col :span="24" class="info">
              <div class="custom-item">发展技师</div>
            </el-col>
            <el-col :span="11">
              <el-form-item label="技师前" prop="num">
                <el-input type="number" v-model.number="biaozhunShow.num" placeholder="请输入技师次数" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5" class="flex-box">
              笔，分成比例
            </el-col>
            <el-col :span="8">
              <el-form-item label="" prop="bfb" label-width="10px">
                <el-input type="number" v-model="biaozhunShow.bfb" placeholder="请输入技师百分比" disabled>
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 发展客户 -->
          <el-row class="fy-row">
            <el-col :span="24" class="info">
              <div class="custom-item">发展客户</div>
            </el-col>
            <el-col :span="11">
              <el-form-item label="客户前" prop="num_1">
                <el-input type="number" v-model.number="biaozhunShow.num_1" placeholder="请输入用户次数" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5" class="flex-box">
              笔，分成比例
            </el-col>
            <el-col :span="8">
              <el-form-item label="" prop="bfb_1" label-width="10px">
                <el-input type="number" v-model="biaozhunShow.bfb_1" placeholder="请输入用户百分比" disabled>
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-button type="default" @click="bzVisible=false">取消</el-button>
          <el-button type="primary" @click="subBzEdit">确认</el-button>
        </el-form>
      </el-dialog>

      <!-- 停牌设置 -->
      <el-dialog title="停牌设置" width="600px" :visible.sync="stopDialog" :modal-append-to-body="false">
        <el-form ref="stopForm" :model="stopForm" label-width="120px" :rules="stopRules">
          <el-row>
            <el-col :span="22">
              <el-form-item label="停牌类型" prop="type_id">
                <el-select v-model="stopForm.type_id" placeholder="请选择停牌类型" clearable style="width: 100%;">
                  <el-option v-for="item in stopTypeList" :key="item.type_id" :label="item.type_name" :value="item.type_id" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="停牌原因" prop="stop_reason">
                <el-input v-model="stopForm.stop_reason" placeholder="请输入停牌原因" type="textarea"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="停牌时间:" prop="stop_type">
                <el-select v-model="stopForm.stop_type" placeholder="请选择停牌时间" clearable style="width: 100%;">
                  <el-option v-for="item in stopTimeList" :key="item.value" :label="item.name" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="22" v-if="stopForm.stop_type===4">
              <el-form-item label="申请时间" prop="stop_start">
                <el-date-picker
                    v-model="stopTime"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @change="stopTimeChange"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24" style="text-align:center">
              <el-button type="default" @click="stopDialog=false">取消</el-button>
              <el-button type="primary" @click="stopSetting">确定</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>

      <RelationService :type="2" @complete="getStore" ref="RelationServiceRef"></RelationService>

		</el-card>
	</div>
</template>

<script>
import {biaozhunAll, serviceTypeAll, setUserBiaozhun} from "@/api/request/service";

  let that;
	import Pagination from '@/components/Pagination/Pagination.vue'
	import RelationService from '@/components/Form/RelationService.vue'
  import {setUserStop,testOperateStopBack} from "@/api/request/partner";
	export default {
		data() {
			return {
				total: 0,
        queryForm: {
          store_name: '',
          type_id: '',
          entry_status: '',
        },
				queryInfo: {
					pagenum: 1,
					pagesize: 10,
				},
				storeList: [],
				//分佣标准
				bzVisible: false,
        stopTimeList:[
          {name: "24小时",value: 1},
          {name: "48小时",value: 2},
          {name: "永久停牌",value: 3},
          {name: "日历选择",value: 4},
        ],
        stopForm: {
          stop_user_id: 0,
          user_type: 4,
          stop_reason: '',
          type_id: null,
          stop_type: null,
          stop_start: '',
          stop_end: '',
        },
        stopDialog: false,
        stopTime: "",
        stopRules: {
          type_id: [{required: true, message: "请输入选择停牌类型", trigger: "change"},],
          stop_reason: [{required: true, message: "请输入真实名称", trigger: "blur"},],
          stop_type: [{required: true, message: "请输入手机号", trigger: "change"},],
        },
        stopTypeList: [],
        typeList: [],
        entryStatusList:[
          {name: '已入驻',value: 1},
          {name: '未入驻',value: 2}
        ],
        biaozhun_id: '',
        activeName: '',
        biaozhunForm: {
          relation_id: '',
          user_type: 4,
          biaozhun: ""
        },
        biaozhun_list: [],
        biaozhunTypeList: [],
        biaozhunShow: {},
			};
		},
		components: {
			Pagination,RelationService
		},
		created() {
			if (!this.$store.state.userInfo) {
          // 防止未登录
          this.$router.push({path: '/login'})
      }else{
		    	that = this;
				  this.getStore();
				  this.getServiceType();
				  this.getBiaozhunList();
			}
		},
		methods: {
      async getServiceType() {
        const res = await serviceTypeAll({})
        if (res.code!==200) this.$message.error(res.msg);
        this.typeList = res.data;
      },
      async getBiaozhunList() {
        const res = await biaozhunAll({user_type: 4})
        console.log(res)
        if (res.status) {
          this.biaozhun_list = res.data;
        } else {
          this.$message.error(res.msg);
        }
      },
			getStore() {
				var url = 'store/store_list';
				let params = {
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
          ...this.queryForm
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.storeList = res.list;
						// that.biaozhun_list = res.biaozhun_list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
        // this.fd_post('index/getDict', {name: "STOP_WORK_TYPE"}).then((res) => {
        //   this.stopTypeList = res.data
        // })
			},
      //查询
      search() {
        this.queryInfo.pagenum = 1;
        this.getStore();
      },
      // 重置
      reset() {
        this.queryInfo.pagenum = 1;
        this.queryForm = {
          store_name: '',
          type_id: '',
          entry_status: '',
        };
        this.getStore();
      },
			//新增门店
			addStore() {
				this.$router.push('/store_list/store_add')
			},
			//编辑
			editStore(id) {
				this.$router.push({
					path: '/store_list/store_add',
					query: {
						id
					}
				})
			},
			//删除
			changeState(item) {
				var url = 'store/delete_store';
				let params = {
					store_id: item.store_id,
				};
				that.fd_post(url, params).then((res) => {
					if(res.status) {
						that.$message.success("操作成功");
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getStore();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getStore();
			},
      //分佣标准
      changeBz(item){
        this.biaozhunForm.relation_id = item.store_id;
        this.biaozhunForm.biaozhun = "";
        this.activeName = ""
        this.biaozhunTypeList = item.type_list.map(item => {
          return {...item, biaozhun_id: item.biaozhun_id?item.biaozhun_id:""}
        });
        if (this.biaozhunTypeList&&this.biaozhunTypeList.length>0) {
          this.activeName = 'type@'+this.biaozhunTypeList[0].type_id
          if (this.biaozhunTypeList[0].biaozhun_id) {
            this.biaozhunShow = this.biaozhun_list.find(item => item.biaozhun_id === this.biaozhunTypeList[0].biaozhun_id)
          }
        }
        this.bzVisible = true;
      },
      changeBiaozhun(biaozhun_id){
        this.biaozhunShow = this.biaozhun_list.find(item => item.biaozhun_id === biaozhun_id)
      },
      async subBzEdit(){
        const biaozhun = this.biaozhunTypeList.map(item => {
          return {
            type_id: item.type_id,
            biaozhun_id: item.biaozhun_id
          }
        })
        this.biaozhunForm.biaozhun = JSON.stringify(biaozhun)
        console.log("【设置标准】",this.biaozhunForm);
        const res = await setUserBiaozhun(this.biaozhunForm).catch((err) => {
          this.$message.error('网络错误');
        })
        if (res.status === 1) {
          this.bzVisible = false;
          this.$message.success("操作成功");
          this.getStore();
        }else{
          this.$message.error(res.msg);
        }
      },
      handleBiaozhunClick(tab, event) {
        if (tab.paneName.indexOf('type@')>-1) {
          const type_id = parseInt(tab.paneName.split('@')[1])
          const bty = this.biaozhunTypeList.find(item => item.type_id === type_id)
          console.log(type_id, bty)
          if (bty.biaozhun_id) {
            this.biaozhunShow = this.biaozhun_list.find(item => item.biaozhun_id === bty.biaozhun_id)
          }
        }
      },
      //打开停用设置
      openStop(item){
        this.stopTypeList = item.type_list
        this.stopForm.stop_user_id = item.store_id
        this.stopForm.stop_reason = ""
        this.stopForm.type_id = null
        this.stopForm.stop_type = null
        this.stopDialog = true
        // testOperateStopBack({})
      },
      stopTimeChange(e) {
        console.log(this.stopTime)
        this.stopForm.stop_start = this.stopTime[0]
        this.stopForm.stop_end = this.stopTime[1]
      },
      async stopSetting() {
        // console.log("【停止表单】",this.stopForm)
        this.$refs.stopForm.validate(async valid => {
          if (valid) {
            const res = await setUserStop(this.stopForm).catch((err) => {
              this.$message.error('网络错误');
            });
            // console.log("【停止222】",res)
            if(res.code === 200) {
              this.stopDialog = false;
              this.$refs.stopForm.resetFields();
              this.$message.success('操作成功');
              this.getStore();
            } else {
              this.$message.error(res.msg);
            }
          }
        });
      },
      relationService(item){
        this.$refs.RelationServiceRef.relationService(item);
      },
		},
	};
</script>

<style lang="scss" scoped>
	.el-table{
	  margin-top: 20px;
	}
  .fy-row{
    margin-bottom: 0px;
  }
  .flex-box{
    display: flex;
    align-items: center;
    height: 38px;
    padding-left: 10px;
  }
  .warning{
    height: 60px;
    padding-left: 20px;
    border-radius: 3px;
    background-color: #FFF6F7;
    border-left: 5px solid #FE6C6F;
    p{
      color: #666;
      line-height: 60px;
      text-align:left;
      font-size: 14px;
    }
  }
  .info {
    padding: 8px 16px;
    background-color: #ecf8ff;
    border-radius: 4px;
    border-left: 5px solid #50bfff;
    margin: 20px 0;
    text-align: left;
    .el-col{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
</style>