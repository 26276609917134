<template>
  <div>
    <!-- 关联服务 -->
    <el-dialog title="关联服务" :visible.sync="serviceVisible" width="600px" top="5vh" @closed="closed">
<!--      <el-row :gutter="10" style="margin-bottom: 20px">-->
<!--        <el-col :span="10">-->
<!--          <el-input placeholder="请输入服务名称" v-model="serviceText" clearable @clear="getService">-->
<!--          </el-input>-->
<!--        </el-col>-->
<!--        <el-col :span="3">-->
<!--          <el-button type="primary" icon="el-icon-search" @click="getService">搜索</el-button>-->
<!--        </el-col>-->
<!--      </el-row>-->
      <!-- 表格 -->
      <el-table ref="xyfwTable" @select="select" @select-all="selectAll"
                :data="serviceTable" style="width: 100%"
                :header-cell-style="{ background: '#eef1f6', color: '#606266' }"  height="calc(100vh - 330px)">
        <el-table-column type="selection"></el-table-column>
        <el-table-column prop="service_id" label="ID" width="100px"></el-table-column>
        <el-table-column label="服务LOGO" width="150px">
          <template slot-scope="scope">
            <div>
              <el-avatar shape="square" size="large" :src="scope.row.service_logo"></el-avatar>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="service_name" label="服务名称"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <Pagination :queryInfo="queryInfoService" :total="serviceTotal" @handleSizeChange="handleSizeChangeService" @handleCurrentChange="handleCurrentChangeService"></Pagination>
      <el-button type="default" @click="serviceVisible = false">取消</el-button>
      <el-button type="primary" @click="relationSubmit">确认</el-button>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/Pagination.vue'
export default {
  name:'RelationService',
  props:{
    type: {
      type: Number,
      default: 1
    }
  },
  components: {
    Pagination
  },
  data(){
    return{
      jishi_id: 0,
      //关联服务
      queryInfoService: {
        pagenum: 1,
        pagesize: 10,
      },
      serviceTotal: 0,
      serviceAll: [],
      serviceTable: [],
      serviceVisible: false,
      serviceText: "",
      serviceList: [],
    }
  },
  watch:{

  },
  methods: {
    //关联服务
    relationService(item){
      if (this.type === 2) {
        this.jishi_id = item.store_id;
      } else {
        this.jishi_id = item.jishi_id;
      }
      this.serviceVisible = true;
      this.serviceList = item.service_list
      this.getServiceList();
    },
    initRelationService(){
      var that = this;
      let service_list = [];
      this.$nextTick(function(){
        that.serviceAll.forEach(item => {
          if (that.serviceList.length>0) {
            that.serviceList.forEach(val=>{
              if(item.service_id === val.service_id){
                service_list.push(item);
              }
            })
          }
        })
        service_list.forEach(row => {
          that.$refs.xyfwTable.toggleRowSelection(row, true);
        });
      });
    },
    getService(){
      this.getServiceList();
    },
    //页数改变
    handleSizeChangeService(newSize) {
      this.queryInfoService.pagenum = 1;
      this.queryInfoService.pagesize = newSize;
      this.serviceTable = this.serviceAll.slice((this.queryInfoService.pagenum-1)*this.queryInfoService.pagesize, this.queryInfoService.pagenum*this.queryInfoService.pagesize)
      this.initRelationService()
    },
    //页码改变
    handleCurrentChangeService(newPage) {
      this.queryInfoService.pagenum = newPage;
      // this.getServiceList();
      this.serviceTable = this.serviceAll.slice((this.queryInfoService.pagenum-1)*this.queryInfoService.pagesize, this.queryInfoService.pagenum*this.queryInfoService.pagesize)
      this.initRelationService()
    },
    getServiceList(){
      var url = 'service/service_list_by';
      let params = {
        is_on_sale: 1,
        service_name: this.serviceText,
        jishi_id: this.jishi_id,
        type: this.type
      };
      this.fd_post(url, params).then((res) => {
        if(res.status) {
          this.serviceAll = res.data
          this.serviceTotal = res.data.length
          this.serviceTable = res.data.slice(0, this.queryInfoService.pagesize)
          this.initRelationService()
          console.log(this.serviceTable)
        } else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        this.$message.error('网络错误');
      });
    },
    relationSubmit() {
      this.serviceVisible = false;
      this.$refs.xyfwTable.clearSelection();
      if(this.serviceList.length>0){
        var url = 'service/relation_jishi';
        let params = {
          jishi_id: this.jishi_id,
          service_json: JSON.stringify(this.serviceList),
          type: this.type
        };
        this.fd_post(url, params).then((res) => {
          if(res.status){
            this.$message.success("操作成功");
            this.$emit('complete',this.wxUserInfo)
          }else{
            this.$message.error(res.msg);
          }
        }).catch((err) => {
          this.$message.error('网络错误');
        });
      }
    },
    select(selection, row) {
      console.log("select",selection)
      console.log("select",this.serviceList)
      this.setSelectService(selection)
      console.log("新的",this.serviceList)
    },
    selectAll(selection){
      console.log("selectAll",selection)
      this.setSelectService(selection)
    },
    setSelectService(selection) {
      var that = this;
      const sels = selection.map(item=>{
        return item.service_id
      })
      const selt = this.serviceTable.map(item=>{
        return item.service_id
      })
      const sell = this.serviceList.map(item=>{
        return item.service_id
      })
      this.serviceList = this.serviceList.filter(item => {
        return !(selt.includes(item.service_id) && !sels.includes(item.service_id));
      })
      const add = selection.filter(item => {
        return !sell.includes(item.service_id);
      })
      add.forEach(item => {
        that.serviceList.push(item);
      })
    },
    closed() {
      this.queryInfoService.pagenum = 1;
    }
  },
}
</script>

<style lang="scss" scoped>
.wx-table{
  width: 100%;
  margin-top:15px;
  //height: calc(100vh - 380px);
  overflow-y: scroll;
}
.header-img{
  width:60px;
  height:60px;
  border-radius: 7px;
  overflow: hidden;
}
</style>